import Service, { service } from '@ember/service';

export default class InsuranceManager extends Service {
  @service organizationManager;
  @service prismic;

  async getInsuranceOffers(categorySlug) {
    let { legalCountry, legalSector } = this.organizationManager.organization;

    let orderings = {
      field: ['my.insurance_offer.order', 'my.insurance_offer.uid'],
    };
    let fetchLinks = ['insurance_risk.name', 'insurance_provider.logo'];

    let segments = await this.prismic.get({
      predicates: [
        this.prismic.prismicLibrary.predicate.at('document.type', 'insurance_segment'),
        this.prismic.prismicLibrary.predicate.fulltext(
          'my.insurance_segment.legal_sectors',
          legalSector
        ),
      ],
    });

    let segmentId = segments.results?.[0]?.id || 'INVALID';

    let basePredicates = [
      this.prismic.prismicLibrary.predicate.at('document.type', 'insurance_offer'),
      this.prismic.prismicLibrary.predicate.at('document.tags', [
        `market_${legalCountry.toLowerCase()}`,
      ]),
    ];

    let segmentPredicates = [
      ...basePredicates,
      this.prismic.prismicLibrary.predicate.at('my.insurance_offer.segments.segment', segmentId),
    ];

    if (categorySlug) {
      segmentPredicates.push(
        this.prismic.prismicLibrary.predicate.at('my.insurance_offer.category', categorySlug)
      );
    }

    let offers = await this.prismic.get({
      predicates: segmentPredicates,
      orderings,
      fetchLinks,
    });

    // Get the insurance offers that should be always displayed
    let alwaysDisplayedPredicates = [
      ...basePredicates,
      this.prismic.prismicLibrary.predicate.at('my.insurance_offer.always_displayed', true),
      ...(categorySlug
        ? [this.prismic.prismicLibrary.predicate.at('my.insurance_offer.category', categorySlug)]
        : []),
    ];

    let alwaysDisplayedOffers = await this.prismic.get({
      predicates: alwaysDisplayedPredicates,
      orderings,
      fetchLinks,
    });

    // Combine offers and alwaysDisplayedOffers, removing duplicates
    let combinedOffers = [...alwaysDisplayedOffers.results, ...offers.results];
    let uniqueOffers = Array.from(new Set(combinedOffers.map(offer => offer.id))).map(id =>
      combinedOffers.find(offer => offer.id === id)
    );

    return uniqueOffers;
  }

  async getInsuranceProvider(slug) {
    let providers = await this.prismic.get({
      predicates: [
        this.prismic.prismicLibrary.predicate.at('document.type', 'insurance_provider'),
        this.prismic.prismicLibrary.predicate.at('my.insurance_provider.slug', slug),
      ],
      pageSize: 1,
    });

    return providers.results[0];
  }
}
