import { CATEGORIES } from 'qonto/constants/insurance-hub';

export const getCategoryTitle = (categorySlug, intl) => {
  switch (categorySlug) {
    case CATEGORIES.BUSINESSES:
      return intl.t('insurance-hub.marketplace.header.title.businesses');
    case CATEGORIES.EMPLOYEES:
      return intl.t('insurance-hub.marketplace.header.title.employees');
    case CATEGORIES.OWNERS:
      return intl.t('insurance-hub.marketplace.header.title.owners');
    default:
      return '';
  }
};
